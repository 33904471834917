export default {
	cinema: {
		address: ' ул. Энтузиастов 11, р4',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: null,
		selectButton: {
			options: [
				{
					value: '//бригантина.кино-75.рф',
					title: 'Бригантина, Чита',
				},
				{
					value: '//нерча.кино-75.рф',
					title: 'Нерча, Нерчинск',
				},
				{
					value: '//авангард.кино-75.рф',
					title: 'Авангард, Кокуй',
				},
				{
					value: '//восток.кино-75.рф',
					title: 'Восток, Борзя',
				},
				{
					value: '//космос.кино-75.рф',
					title: 'Космос, Балей',
				},
				{
					value: '//россия.кино-75.рф',
					title: 'Россия, Первомайский',
				},
				{
					value: '//экран.кино-75.рф',
					title: 'Экран, Карымское',
				},
				{
					value: '//даурия.кино-75.рф',
					title: 'Даурия, Краснокаменск',
				},
			],
			selectedOptionValue: '//космос.кино-75.рф',
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
